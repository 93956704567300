import { Button, ListItem, List } from '@mui/material';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ListItemIcon from '@mui/material/ListItemIcon';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import GitHubIcon from '@mui/icons-material/GitHub';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';

export default function SocialList() {
  return (
    <List sx={{ display: 'flex', marginBottom: '32px' }} component='nav'>
      <ListItem sx={{ padding: '0', width: 'auto' }}>
        <Button href='tel:+12898891995'>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
        </Button>
      </ListItem>
      <ListItem sx={{ padding: '0', width: 'auto' }}>
        <Button href='https://www.linkedin.com/in/stasfedorko/' target='_blank'>
          <ListItemIcon>
            <LinkedInIcon />
          </ListItemIcon>
        </Button>
      </ListItem>
      <ListItem sx={{ padding: '0', width: 'auto' }}>
        <Button href='https://github.com/stasfedorko' target='_blank'>
          <ListItemIcon>
            <GitHubIcon />
          </ListItemIcon>
        </Button>
      </ListItem>
      <ListItem sx={{ padding: '0', width: 'auto' }}>
        <Button href='https://wa.me/12898891995?text=hello, ' target='_blank'>
          <ListItemIcon>
            <WhatsAppIcon />
          </ListItemIcon>
        </Button>
      </ListItem>
      <ListItem sx={{ padding: '0', width: 'auto' }}>
        <Button href='https://t.me/stasfedorko' target='_blank'>
          <ListItemIcon>
            <TelegramIcon />
          </ListItemIcon>
        </Button>
      </ListItem>
      <ListItem sx={{ padding: '0', width: 'auto' }}>
        <Button href='https://www.instagram.com/stasfedorko/' target='_blank'>
          <ListItemIcon>
            <InstagramIcon />
          </ListItemIcon>
        </Button>
      </ListItem>
    </List>
  );
}
