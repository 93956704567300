import { Box } from '@mui/material';
import customTheme from 'components/app-theme';
import FooterBottom from './footer-bottom';
import FooterTop from './footer-top';

const Footer = () => {
  return (
    <Box id='contact' sx={{ backgroundColor: customTheme.palette.grey[900] }}>
      <FooterTop />
      <FooterBottom />
    </Box>
  );
};
export default Footer;
