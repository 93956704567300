export const exampleOfWork = [
  {
    title: 'mythicalbeings',
    text: 'react, responsive website',
    img: 'https://fedorko.pro/ex-works-img/ex-work-7.png',
    imgAlt:
      'example of work - responsive website - fronend developer stan fedorko',
    link: 'https://mythicalbeings.io/',
  },
  {
    title: 'mooiwurk',
    text: 'responsive website',
    img: 'https://fedorko.pro/ex-works-img/ex-work-1.png',
    imgAlt:
      'example of work - responsive website - fronend developer stan fedorko',
    link: 'https://www.mooiwurk.nl/',
  },
  {
    title: 'kade-z',
    text: 'responsive website',
    img: 'https://fedorko.pro/ex-works-img/ex-work-2.png',
    imgAlt:
      'example of work - responsive website - fronend developer stan fedorko',
    link: 'https://www.kade-z.nl/',
  },
  {
    title: 'cams project',
    text: 'react, api, ux/ui',
    img: 'https://fedorko.pro/ex-works-img/ex-work-8.png',
    imgAlt:
      'example of work - react, API, responsive website - fronend developer stan fedorko',
    link: 'https://stan3.netlify.app/',
  },
  {
    title: 'welgelegen workum',
    text: 'responsive website',
    img: 'https://fedorko.pro/ex-works-img/ex-work-3.png',
    imgAlt:
      'example of work - responsive website - fronend developer stan fedorko',
    link: 'https://www.welgelegen-workum.nl/',
  },
  {
    title: 'huisartsengrijpskerk',
    text: 'responsive website',
    img: 'https://fedorko.pro/ex-works-img/ex-work-4.png',
    imgAlt:
      'example of work - responsive website - fronend developer stan fedorko',
    link: 'https://www.huisartsengrijpskerk.nl/',
  },
  {
    title: 'auto huis aduard',
    text: 'responsive website',
    img: 'https://fedorko.pro/ex-works-img/ex-work-5.png',
    imgAlt:
      'example of work - responsive website - fronend developer stan fedorko',
    link: 'https://www.autohuisaduard.nl/',
  },
  {
    title: 'de ster zuidhorn',
    text: 'responsive website',
    img: 'https://fedorko.pro/ex-works-img/ex-work-6.png',
    imgAlt:
      'example of work - responsive website - fronend developer stan fedorko',
    link: 'https://www.desterzuidhorn.nl/',
  },
];
