import Masonry from '@mui/lab/Masonry';
import { Box, Typography, Container, Button } from '@mui/material';
import { exampleOfWork } from 'components/api';
import CardWork from 'components/card-work';
import { nanoid } from 'nanoid';

const WorksSection = () => {
  const title = 'my works';
  const btnText = 'contact';
  const btnHref = 'tel:+12898891995';

  return (
    <Box
      id='works'
      sx={{
        padding: { xs: '50px 0px', md: '100px 0px' },
        minHeight: '70vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container maxWidth='lg'>
        <Typography
          variant='h2'
          gutterBottom
          component='h2'
          sx={{ fontWeight: '300' }}
        >
          {title}
        </Typography>

        <Box
          component='div'
          mb={3}
          sx={{ position: 'relative', overflow: 'hiden' }}
        >
          <Masonry columns={{ xs: 1, sm: 1, md: 3, lg: 4 }} spacing={2}>
            {exampleOfWork.map((item: any) => {
              return <CardWork item={item} key={nanoid()} />;
            })}
          </Masonry>
        </Box>

        <Button variant='outlined' color='info' size='large' href={btnHref}>
          {btnText}
        </Button>
      </Container>
    </Box>
  );
};
export default WorksSection;
