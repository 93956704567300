import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@mui/material';

const SkillsSection = () => {
  const title = 'my skills';
  const btnText = 'my works';
  const btnHref = '#works';

  return (
    <Box
      id='skills'
      sx={{
        padding: { xs: '50px 0px', md: '100px 0px' },
        minHeight: '80vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container maxWidth='lg'>
        <Typography
          variant='h2'
          gutterBottom
          component='h2'
          sx={{ fontWeight: '300' }}
        >
          {title}
        </Typography>

        <Box sx={{ maxWidth: '782px', mb: 3 }}>
          <List sx={{ display: 'flex', flexWrap: 'wrap', marginLeft: '-16px' }}>
            {[
              'html',
              'css',
              'javascript',
              'react js',
              'api',
              'git',
              'mui',
              'mobx',
              'gatsby js',
              'bootstrap',
              'scss',
              'gulp',
              'pug',
              'figma',
              'sketch',
              'zeplin',
              'photoshop',
            ].map((text) => (
              <ListItem key={text} sx={{ width: 'auto' }}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Button variant='outlined' color='info' size='large' href={btnHref}>
          {btnText}
        </Button>
      </Container>
    </Box>
  );
};
export default SkillsSection;
