import { Box, Container, Grid, Link, Typography } from '@mui/material';
import customTheme from 'components/app-theme';
import SocialList from 'components/social-list';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const FooterTop = () => {
  const title = 'want to reach out?';
  const text1 =
    'questions about my work or my work opportunities, send me a note! ';
  const emailLink = 'stan.fedorko@gmail.com';
  // const btnHref2 = '#skills';

  return (
    <Box
      sx={{
        padding: { xs: '50px 0px', md: '100px 0px' },
      }}
    >
      <Container maxWidth='lg'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography
              variant='h3'
              gutterBottom
              component='h3'
              sx={{ fontWeight: '300', color: customTheme.palette.grey[400] }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              component='div'
              mb={4}
              sx={{
                fontWeight: '300',
                color: customTheme.palette.grey[400],
              }}
            >
              {text1}
              <Link
                href='mailto:stan.fedorko@gmail.com'
                target='blank'
                underline='hover'
                sx={{
                  color: customTheme.palette.grey[200],
                }}
              >
                {emailLink}
              </Link>
            </Typography>

            <SocialList />

            <Typography
              component='div'
              sx={{
                color: customTheme.palette.grey[200],
              }}
            >
              <Link
                href='https://fedorko.pro/Stan-Fedorko-CV.pdf'
                target='blank'
                color='inherit'
                fontWeight={300}
                underline='hover'
              >
                <PictureAsPdfIcon sx={{ margin: '0 6px', fontSize: '18px' }} />
                dowload cv
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default FooterTop;
