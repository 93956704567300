import {
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from '@mui/material';

const CardWork = ({ item }: any) => {
  const { title, text, img, imgAlt, link } = item;

  return (
    <Card sx={{ wiidth: '100%', maxWidth: '345px' }}>
      <CardMedia
        component='img'
        image={img}
        alt={imgAlt}
        sx={{
          height: '260px',
          objectPosition: 'top',
          transition: 'all 4s ease-in-out',
          '&:hover': { objectPosition: 'bottom' },
        }}
      />
      <CardContent sx={{ padding: '12px 12px 0' }}>
        <Typography
          gutterBottom
          variant='h6'
          component='div'
          sx={{ fontWeight: '300' }}
        >
          {title}
        </Typography>
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{ fontWeight: '300' }}
        >
          {text}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size='small'
          color='info'
          href={link}
          target='_blank'
          variant='outlined'
          sx={{ fontSize: '14px' }}
        >
          viewing
        </Button>
      </CardActions>
    </Card>
  );
};
export default CardWork;
