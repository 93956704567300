import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
// import Button from '@mui/material/Button';
// import MenuItem from '@mui/material/MenuItem';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

// const menuItems = ['about', 'contact'];

const ResponsiveAppBar = () => {
  // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
  //   null
  // );

  // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElNav(event.currentTarget);
  // };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  return (
    <AppBar position='static'>
      <Container maxWidth='lg'>
        <Toolbar disableGutters>
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {menuItems.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Button href={'#' + page} sx={{ color: 'white' }}>
                    {page}
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

          {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {menuItems.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                href={'#' + page}
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                  textTransform: 'lowercase',
                  letterSpacing: '.08rem',
                  fontWeight: '300',
                }}
              >
                {page}
              </Button>
            ))}
          </Box> */}


          <Typography
            noWrap
            component='a'
            href='tel:+12898891995'
            sx={{
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none',
              textAlign: 'left',
            }}
          >
            <LocalPhoneIcon
              sx={{
                display: { xs: 'inline-block', md: 'none' },
              }}
            />
            <Box
              component='span'
              sx={{
                fontSize: '14px',
                display: { xs: 'none', md: 'inline-block', fontWeight: '300' },
              }}
            >
              <LocalPhoneIcon
                sx={{
                  fontSize: '20px',
                  marginRight: '7px',
                  marginBottom: '-5px',
                }}
              />
              +1 289 889-1995
            </Box>
          </Typography>


          <Typography
            variant='h5'
            noWrap
            component='a'
            href=''
            sx={{
              mr: 2,
              flexGrow: 1,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              textAlign: 'center',
              fontWeight: '300',
            }}
          >
            stan fedorko
          </Typography>

          <Typography
            noWrap
            component='a'
            href='mailto:stan.fedorko@gmail.com'
            sx={{
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none',
              textAlign: 'right',
            }}
          >
            <EmailIcon
              sx={{
                display: { xs: 'inline-block', md: 'none' },
              }}
            />
            <Box
              component='span'
              sx={{
                fontSize: '14px',
                display: { xs: 'none', md: 'inline-block', fontWeight: '300' },
              }}
            >
              <EmailIcon
                sx={{
                  fontSize: '20px',
                  marginRight: '7px',
                  marginBottom: '-5px',
                }}
              />
              stan.fedorko@gmail.com
            </Box>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
