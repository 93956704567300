import { createTheme } from '@mui/material/styles';
import { blue, grey, green, orange, red, purple } from '@mui/material/colors';

const primaryColor = blue[800];
const secondaryColor = purple[800];
const lightColor = grey.A400;
const successColor = green[800];
const warningColor = orange[800];
const dangerColor = red[800];

const customTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    success: {
      main: successColor,
    },
    warning: {
      main: warningColor,
    },
    error: {
      main: dangerColor,
    },
    info: {
      main: lightColor,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          textTransform: 'lowercase',
          fontSize: '18px',
          minWidth: 'auto',
          fontWeight: '300',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          textTransform: 'lowercase',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
        },
      },
    },
  },
});

export default customTheme;
