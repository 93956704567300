import { Box, Container, Typography } from '@mui/material';
import customTheme from 'components/app-theme';

const FooterBottom = () => {
  const copyrightText = '© all rights reserved - stan fedorko';

  return (
    <Box
      sx={{
        padding: '20px 0px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container maxWidth='lg'>
        <Typography
          component='div'
          sx={{
            fontWeight: '300',
            textAlign: 'center',
            color: customTheme.palette.grey[600],
            fontSize: '14px',
          }}
        >
          {copyrightText}
        </Typography>
      </Container>
    </Box>
  );
};
export default FooterBottom;
