import { Box, Button, Typography, Container } from '@mui/material';
import customTheme from 'components/app-theme';

const AboutSection = () => {
  const title = 'about';
  const btnText = 'my skills';
  const btnHref = '#skills';

  return (
    <Box
      id='about'
      sx={{
        padding: { xs: '50px 0px', md: '100px 0px' },
        minHeight: '80vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container maxWidth='lg'>
        <Typography component='div'>
          <Typography
            variant='h2'
            gutterBottom
            component='h2'
            sx={{ fontWeight: '300' }}
          >
            {title}
          </Typography>
          <Typography
            variant='body1'
            gutterBottom
            sx={{
              mb: 4,
              fontWeight: '300',
              maxWidth: '782px',
              fontSize: '18px',
              textTransform: 'lowercase',
              color: customTheme.palette.info.main,
            }}
          >
            I have been working as a front-end developer for over six years.{' '}
            <Box component='span' sx={{ color: '#fff' }}>
              html
            </Box>{' '}
            and{' '}
            <Box component='span' sx={{ color: '#fff' }}>
              css
            </Box>{' '}
            is what I'm really good at. I am currently working with{' '}
            <Box component='span' sx={{ color: '#fff' }}>
              react
            </Box>
            . And this is where I want to develop.
          </Typography>
        </Typography>
        <Button variant='outlined' color='info' size='large' href={btnHref}>
          {btnText}
        </Button>
      </Container>
    </Box>
  );
};
export default AboutSection;
