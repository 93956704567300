import { ThemeProvider } from '@mui/material/styles';
import customTheme from 'components/app-theme';
import ResponsiveAppBar from 'components/app-bar';
import HomeSection from 'components/section-home';
import SkillsSection from 'components/section-skills';
import AboutSection from 'components/section-about';
import WorksSection from 'components/section-works';
import Footer from 'components/footer';

import './style.css';

const App = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <ResponsiveAppBar />
      <HomeSection />
      <AboutSection />
      <SkillsSection />
      <WorksSection />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
