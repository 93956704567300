import { Box, Button, Typography, Container, Stack } from '@mui/material';
import customTheme from 'components/app-theme';

const HomeSection = () => {
  const titleText = [
    {
      title1: 'howdy',
      title2: `i'm stan`,
      title3: 'frontend developer',
    },
  ];
  const btnText1 = 'about';
  const btnText2 = 'skills';
  const btnHref1 = '#about';
  const btnHref2 = '#skills';

  return (
    <Box
      sx={{
        padding: { xs: '50px 0px', md: '100px 0px' },
        minHeight: '70vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container maxWidth='lg'>
        <Typography component='blockquote'>
          <Typography
            variant='h2'
            gutterBottom
            component='h2'
            sx={{ fontWeight: '300' }}
          >
            <Box
              component='span'
              sx={{
                display: 'inline-block',
                color: customTheme.palette.secondary.main,
              }}
            >
              {titleText[0].title1},
            </Box>
            <br></br>
            <Box component='span' sx={{ color: customTheme.palette.info.main }}>
              {titleText[0].title2},
            </Box>
            <br></br>
            <Box
              component='span'
              sx={{
                display: 'inline-block',
              }}
            >
              {titleText[0].title3}.
            </Box>
          </Typography>
        </Typography>
        <Stack spacing={2} direction='row'>
          <Button variant='outlined' color='info' size='large' href={btnHref1}>
            {btnText1}
          </Button>
          <Button variant='outlined' color='info' size='large' href={btnHref2}>
            {btnText2}
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};
export default HomeSection;
